<template>
  <div
    class="image-carousel-wrapper w-full flex flex-col relative"
    v-resize
    @resize="onResize"
  >
    <div @click="openViewer" class="viewer flex justify-center items-center">
      <eva-icon name="plus-outline" :width="24" :height="24" :fill="'#fff'" />
    </div>

    <div
      v-if="images.length > 0"
      class="main-image"
      :style="{
        backgroundImage: images.length > 0 ? `url(${images[index]})` : '',
      }"
    />

    <div class="w-full flex flex-row">
      <div class="flex-1">
        <vueper-slides
          ref="vueperslides1"
          class="no-shadow"
          :arrows="false"
          :bullets="false"
          :slideRatio="1"
          :visible-slides="slideCount"
          :gap="0"
          fixed-height="92px"
        >
          <vueper-slide v-for="(image, i) in images" :key="i">
            <template #content>
              <div class="vueperslide__content-wrapper">
                <div class="carousel-image" @click="() => onClickThumbnail(i)">
                  <div v-if="index !== i" class="dim" />
                  <div
                    class="sub-image"
                    :style="{ backgroundImage: `url(${image})` }"
                  />
                </div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>

      <div class="navigation flex flex-row items-center justify-center">
        <button @click="onPrev">
          <eva-icon
            name="chevron-left-outline"
            :width="40"
            :height="40"
            :fill="'#fff'"
          />
        </button>
        <button @click="onNext">
          <eva-icon
            name="chevron-right-outline"
            :width="40"
            :height="40"
            :fill="'#fff'"
          />
        </button>
      </div>
    </div>

    <div class="hidden">
      <ImageViewer :images="images" />
    </div>
  </div>
</template>

<script>
import ImageViewer from "@/components/ImageViewer.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "ImageCarousel",
  components: {
    VueperSlides,
    VueperSlide,
    ImageViewer,
  },
  props: {
    images: Array,
  },
  data() {
    return {
      index: 0,
      width: 0,
      ro: null,
    };
  },
  methods: {
    onClickThumbnail: function(i) {
      this.index = i;
    },
    onResize(e) {
      this.width = e.detail.width;
    },
    onNext() {
      let nextIndex = this.index + 1;
      if (nextIndex === this.images.length) {
        nextIndex = 0;
      }
      this.index = nextIndex;
      this.$refs.vueperslides1.goToSlide(nextIndex);
    },
    onPrev() {
      let prevIndex = this.index - 1;
      if (prevIndex < 0) {
        prevIndex = this.images.length - 1;
      }
      this.index = prevIndex;
      this.$refs.vueperslides1.goToSlide(prevIndex);
    },
    openViewer() {
      this.$viewerApi({
        images: this.images,
      });
    },
  },
  computed: {
    slideCount: function() {
      return (this.width - 180) / 92;
    },
  },
};
</script>

<style scoped lang="scss">
.image-carousel-wrapper {
  height: 592px;

  .main-image {
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .dim {
    position: absolute;
    width: 92px;
    height: 92px;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .sub-image {
    width: 92px;
    height: 92px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .navigation {
    width: 180px;
    background-color: #e8e8e8;
    height: 92px;

    button {
      width: 60px;
    }
  }

  .viewer {
    position: absolute;
    top: 0;
    right: 0;
    width: 38px;
    height: 38px;
    background-color: #ddd;
    cursor: pointer;
  }
}

@media (max-width: 639px) {
  .image-carousel-wrapper {
    height: 250px;
    .main-image {
      min-height: 200px;
    }
    .navigation {
      height: 50px;
      width: 90px;
    }
  }
}
</style>
